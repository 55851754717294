<template>
    <div class="card">
        <div class="bg-blue-light px-1 rounded pb-2">
            <TitleButton
                class="mt-1"
                btnTitle="Go Back"
                title="Create Journal"
                @onClickAddNewButton="goToList"
            />
            <div class="row mt-1">
                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                    <label class="col-form-label">Date</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date" 
                        placeholder=""
                        v-model="journal.date"
                    >
                </div>
                
                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                    <label class="col-form-label">Business</label>
                    <select name="" id="business" class="form-control flatpickr-input invoice-edit-input" v-model="journal.business_id">
                        <option value="" selected>Select One</option>
                        <option v-for="business in businesses" :value="business.id">{{business.name}}</option>
                    </select>
                </div>

                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                    <label class="col-form-label">Party</label>
                    <select name="" id="business" class="form-control flatpickr-input invoice-edit-input" v-model="journal.contact_profile_id">
                        <option value="" selected>Select One</option>
                        <option v-for="contactProfile in contactProfiles" :value="contactProfile.id">{{contactProfile.name}}</option>
                    </select>
                </div>

                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                    <label class="col-form-label">Voucher No</label>
                    <div class="input-group input-group-merge invoice-edit-input-group">
                        <div class="input-group-text">
                            <span>{{ journal.voucher_prefix }}-</span>
                        </div>
                        <input type="number" min="1" class="form-control invoice-edit-input" placeholder="" v-model="journal.voucher_serial">
                    </div>
                </div>
            </div>
        </div>

        <div class="px-2 mt-2">
            <AddFormElement
                class="mb-2"
                :accountHeads="accountHeads"
                v-for="(item, index) in journal.general_ledgers"
                :key="index"
                :index="index"
                :item="item"
                @onClose="onClose"
            />
        </div>
        <div class="row me-1 mt-3 px-2">
            <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                <AddButton title="Add line" @onClickAdd="onClickAdd"/>
            </div>
            <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control text-end" :value="totalDebit"></div>
            <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control text-end" :value="totalCredit"></div>
        </div>

        <div class="row mt-3 px-2">
            <div class="col-12">
                <div class="mb-2">
                    <label for="note" class="form-label fw-bold">Memo</label>
                    <textarea v-model="journal.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                </div>
            </div>
        </div>

        <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
            <hr class="mb-1">
            <button
                @click="saveJournal(true)"
                :disabled="!isDebitCreditEqual || loading"
                class="btn btn-primary"
            >
                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Save
            </button>
            <button
                @click="saveJournal(false)"
                :disabled="!isDebitCreditEqual || saveNewLoader"
                class="btn btn-primary"
            >
                <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Save & New
            </button>
            <button
                @click="goToList" 
                class="btn btn-outline-secondary cancel-btn"
            >
                Cancel
            </button>
        </div>
    </div>
</template>

<script>
import handleJournal from '@/services/modules/journal'
import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'
import {generateVoucherNumber, generateQuery} from "@/services/utils/voucherNumberGenerator";
import { inject } from 'vue'
// import { useStore } from 'vuex'
// import { computed } from 'vue'
// import {mapMutations} from 'vuex'

import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/journal/AddFormElement'
import AddButton from '@/components/atom/AddButton'

export default {
    name: 'JournalCreate',
    components: {
        TitleButton,
        AddFormElement,
        AddButton
    },
    data: () => ({
        accountHeads: [],
        saveNewLoader: false,
        contactProfiles : [],
        businesses : [],
        journal: {
            company_id:'',
            contact_profile_id: '',
            business_id: '',
            date: '',
            note: '',
            mop_references: 'mop',
            voucher_type: 'cash_voucher',
            voucher_prefix : '',
            voucher_serial : '',
            voucher_no : '',
            general_ledgers: [
                {
                    account_head_id: null,
                    debit: null,
                    credit: null,
                    note: '',
                    tax_rate: null,
                    taxable_amount: null
                },
                {
                    account_head_id: null,
                    debit: null,
                    credit: null,
                    note: '',
                    tax_rate: null,
                    taxable_amount: null
                }
            ]
        }
    }),
    computed: {
        isLoading () {
            return this.loading || this.saveNewLoader
        },
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        general () {
            return this.journal.general_ledgers
        },
        totalDebit () {
            let total = 0;
            this.general.map(i => {
                if(i.debit) total += i.debit
            })
            return total
        },
        totalCredit () {
            let total = 0;
            this.general.map(i => {
                if(i.credit) total += i.credit
            })
            return total
        },
        isDebitCreditEqual () {
            return this.totalCredit === this.totalDebit
        }
    },
    watch: {
        'journal.voucher_prefix':function () {
            this.concatVoucher()
        },
        
        'journal.voucher_serial':function () {
            this.concatVoucher()
        }
    },
    
    methods: {
        concatVoucher(){
            this.journal.voucher_no = this.journal.voucher_prefix + '-' + this.journal.voucher_serial
        },
        
        async getAccountHeads() {
           // return;
            try {
                this.loading = true
                let res = await this.fetchAccountHeads(this.$route.params.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.accountHeads = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getContactProfiles() {
        try {
          let companyId = this.$route.params.companyId
          let contactId = '';
          let q = '';
          let query = '?company_id=' + companyId + '&q=' + q + '&contact_id' + contactId;
          let res = await this.fetchContactProfiles(query)

          if(!res.status) {
          }
          if(res.status) {
            this.contactProfiles = res.data;
          }

        } catch (err) {
          if(!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if(err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.loading = false
        }
      },

        async getBusinesses(){
        try {
          let companyId = this.$route.params.companyId
          let res = await this.fetchBusinessList('?company_id=' + companyId)

          if(!res.status) {
          }
          if(res.status) {
            this.businesses = res.data;
          }

        } catch (err) {
          if(!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if(err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.loading = false
        }

      },

        goToList() {
            this.$router.push({name: 'journal-list', 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
            
            })
        },
        onClose(index) {
            this.journal.general_ledgers.splice(index, 1)
        },
        onClickAdd() {
            this.journal.general_ledgers.push({
                account_head_id: null,
                debit: '',
                credit: '',
                note: '',
                tax_rate: null,
                taxable_amount: null
            })
        },
        resetForm() {
            this.journal = {
                company_id:'',
                date: '',
                note: '',
                mop_references: 'mop',
                voucher_type: 'cash_voucher',
                general_ledgers: [
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: '',
                        tax_rate: null,
                        taxable_amount: null
                    },
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: '',
                        tax_rate: null,
                        taxable_amount: null
                    }
                ]
            }
        },
        getFormData () {
            let formData = new FormData();
            Object.keys(this.journal).forEach(i => {
                if(i !== 'general_ledgers') {
                    formData.append(i, this.journal[i])
                }
            })
            formData.append('general_ledgers', JSON.stringify(this.journal.general_ledgers))
            return formData;
        },
        async saveJournal (redirect = false) {//createJournal
            this.journal.company_id = this.$route.params.companyId;
            let data = this.getFormData();
            if(redirect) {
                this.loading = true
            } else {
                this.saveNewLoader = true
            }
            try {
                let res = await this.createJournal(data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.resetForm()
                    if(redirect) this.goToList()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.data?.message)
                }
            } finally {
                this.loading = false
                this.saveNewLoader = false
            }
        }
    },
    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');

        const {
            fetchAccountHeads,
            createJournal,
            loading
        } = handleJournal()

        const {
          fetchContactProfiles,
        } = handleContact()

        const {
          fetchBusinessList,
        } = handleCBusinesses()

        return {
            fetchAccountHeads,
            fetchContactProfiles,
            fetchBusinessList,
            createJournal,
            loading,
            showError,
            showSuccess
        }
    },

    async mounted() {
        await this.getAccountHeads()
        await this.getContactProfiles()
        await this.getBusinesses()
        this.journal.date = new Date().toISOString().split('T')[0];
        let voucher = await generateVoucherNumber(generateQuery(this.$route.params.companyId, 'journal_voucher', 'general_journal  '));
        this.journal.voucher_prefix = voucher.prefix;
        this.journal.voucher_serial = voucher.serial;
    }
}
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
    .label-min-width {
        min-width: 80px
    }
</style>
